// src/hooks/useProductFetcher.tsx
import { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { ServiceType } from '../types/types';

export const useServiceFetcher = (firmId: number | null = null, city: string | null = null, filters: Record<string, string> = {}) => {
    const [services, setServices] = useState<ServiceType[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    
    // Filtreler, firmId veya city değiştiğinde sayfa numarasını 1'e sıfırla
    useEffect(() => {
        setPage(1);
    }, [firmId, city, JSON.stringify(filters)]);

    const fetchServices = async () => {
        try {
            // Temel parametreleri oluştur
            const params: any = {
                page: page,
                include: 'sellers.firm',
            };

            // Firma ID'si varsa ekle
            if (firmId) {
                params['filter[firm_id]'] = firmId;
            }

            // Şehir varsa ekle - sadece firm.city parametresi kullanılmalı
            if(city) {
                params['filter[firm.city]'] = city;
                console.log('Şehir filtresi uygulanıyor (hizmetler):', city);
            }

            // NOT: Diğer filtreler hizmetlerde kullanılmıyor
            // Sadece şehir filtresi kullanılıyor

            console.log('API çağrısı parametreleri (hizmetler):', params);
            const response = await axiosInstance.get('/ecommerce/services', { params });
            const newServices = response.data.data;
            
            // Eğer sayfa 1 ise veya filtreler değiştiyse, tüm servisleri yeni yanıtla değiştir
            // Aksi takdirde, mevcut servislere yeni servisleri ekle
            if (page === 1) {
                setServices(newServices);
            } else {
                setServices(prev => [...prev, ...newServices]);
            }
            
            setHasMore(newServices.length > 0);
            setLoading(false);
        } catch (err) {
            setError('Servisler yüklenirken bir hata oluştu');
            setLoading(false);
            console.error('API Hatası:', err);
        }
    };

    const resetServices = () => {
        setServices([]);
        setPage(1);
        setHasMore(true);
        setLoading(true);
    };

    // firmId, city veya filters değiştiğinde servisleri sıfırla
    useEffect(() => {
        resetServices();
    }, [firmId, city, JSON.stringify(filters)]);

    // Sayfa, firmId, city veya filters değiştiğinde servisleri yükle
    useEffect(() => {
        console.log('Servisler yükleniyor. Sayfa:', page, 'FirmId:', firmId, 'City:', city);
        fetchServices();
    }, [page, firmId, city, JSON.stringify(filters)]);

    const loadMore = () => {
        setPage(prev => prev + 1);
    };

    return { services, loading, error, hasMore, loadMore, resetServices };
};