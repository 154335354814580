// src/components/Product.tsx
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faGasPump, faCloudShowersHeavy, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { ProductType, SubProductType } from '../types/types';
import AddToCartButton from './AddToCartButton';
import { useCart } from '../contexts/CartContext';
import {useProductPopup} from "../contexts/ProductPopupContext";
import { useNavigate } from 'react-router-dom';

interface ProductProps {
    product: ProductType;
}

const Product: React.FC<ProductProps> = ({ product }) => {
    const { cartItems, getItemQuantity } = useCart();
    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState<SubProductType>(product.products[0]);
    const { openPopup, openProductDetails } = useProductPopup();

    // Yedek resim kaynağı
    const fallbackImage = 'https://panel.lastsis.com/media/default-images/tire-default.jpeg';

    useEffect(() => {
        const cartProduct = cartItems.find((item) =>
            product.products.some((p) => p.id === item.product_id)
        );

        if (cartProduct) {
            const matchedProduct = product.products.find((p) => p.id === cartProduct.product_id);
            if (matchedProduct) {
                setSelectedProduct(matchedProduct);
            }
        } else {
            setSelectedProduct(product.products[0]);
        }
    }, [cartItems, product.products]);

    return (
        <div className="w-1/2 lg:w-1/4">
            <div className="p-1">
                <div className="w-full bg-white flex justify-between items-center p-2">
                    <span className="season-icon leading-[10px]">
                        {product?.season ? (
                            <img
                                src={product?.season.includes('Yaz') 
                                    ? '/summer.png' 
                                    : product?.season.includes('Kış') 
                                        ? '/winter.png' 
                                        : '/allseason.png'
                                }
                                alt={product?.season}
                                className="h-5 w-5 md:h-7 md:w-7 object-contain"
                                style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                title={`${product?.season} Lastiği`}
                            />
                        ) : null}
                    </span>
                    <div className="hidden md:flex flex-row">
                        <div className="flex justify-center text-center space-x-2">
                            <div className="flex flex-col items-center">
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faGasPump} 
                                        className="text-yellow-500 h-3"
                                        style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                    />
                                </div>
                                <div className="leading-[10px]">
                                    <span className="mr-1 text-[10px]">{product.energy_class}</span>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faCloudShowersHeavy} 
                                        className="text-blue-500 h-3"
                                        style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                    />
                                </div>
                                <div className="leading-[10px]">
                                    <span className="mr-1 text-[10px]">{product.brake_performance}</span>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faVolumeUp} 
                                        className="text-red-500 h-3"
                                        style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                        title="Değer db cinsindendir."
                                    />
                                </div>
                                <div className="leading-[10px] whitespace-nowrap">
                                    <span className="text-[10px]">{product.noise_level}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-white">
                    <img
                        className="w-[175px] h-[175px] cursor-pointer object-contain mx-auto"
                        alt={product.name}
                        src={`https://d2buqeq24qhiv5.cloudfront.net/250x250/pool/${product.real_image}`}
                        onError={(e) => {
                            (e.target as HTMLImageElement).src = fallbackImage;
                        }}
                        onClick={() => navigate(`/product/${product.id}`)}
                    />
                </div>
                <div className="cat-pro-info bg-white p-2">
                    <div 
                        className="flex w-100 cursor-pointer" 
                        onClick={() => navigate(`/product/${product.id}`)}
                    >
                        <h3
                            className="font-bold text-[13px] md:text-[15px] text-[#333] leading-[16px] md:leading-[18px] overflow-hidden text-ellipsis hover:text-blue-500"
                            style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                            title={product.name}
                        >
                            {product.name}
                        </h3>
                    </div>
                    
                    {/* Mobil için Enerji Sınıfı Gösterimi */}
                    <div className="md:hidden flex justify-start space-x-3 mt-2 mb-1">
                        <div className="flex items-center space-x-1">
                            <FontAwesomeIcon 
                                icon={faGasPump} 
                                className="text-yellow-500 h-3"
                            />
                            <span className="text-[10px] text-gray-600">{product.energy_class}</span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <FontAwesomeIcon 
                                icon={faCloudShowersHeavy} 
                                className="text-blue-500 h-3"
                            />
                            <span className="text-[10px] text-gray-600">{product.brake_performance}</span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <FontAwesomeIcon 
                                icon={faVolumeUp} 
                                className="text-red-500 h-3"
                            />
                            <span className="text-[10px] text-gray-600">{product.noise_level}</span>
                        </div>
                    </div>

                    <div className="cat-pro-brand-info my-0.5 flex flex-col w-100">
                        <strong className="prod-brand-title">
                            <a href="#" className="text-[#1959d1] text-[12px] md:text-[14px] mr-2">{product.brand}</a>
                            <span className="text-[10px] md:text-[12px] text-[#333]">({product.stock_code})</span>
                        </strong>
                        <div className="flex flex-row justify-between items-center center my-1">
                            <strong className="text-[14px] md:text-[16px] text-[#0a8c06]">{selectedProduct?.vatted_price_formatted ?? 0} TL</strong>
                            <span 
                                className="text-[10px] md:text-[12px] text-[#333] font-bold cursor-pointer" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/product/${product.id}?scrollTo=sellers`);
                                }}
                            >
                                Satıcılar ({selectedProduct?.firm_count ?? 0})
                            </span>
                        </div>
                    </div>
                    
                    {/* DOT ve Fiyat Listesi - Yatay Scroll */}
                    <div className="relative w-full">
                        <div className="flex flex-row overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 pb-2 -mx-2 px-2">
                            {product.products.map((item) => (
                                <div 
                                    key={item.id}
                                    onClick={() => setSelectedProduct(item)}
                                    className={`flex-shrink-0 mr-2 last:mr-0`}
                                >
                                    <div className={`flex flex-col rounded-[6px] ${
                                        selectedProduct?.id === item.id 
                                            ? 'bg-green-500 bg-opacity-10 border-green-500' 
                                            : 'bg-blue-500 bg-opacity-10 border-gray-300'
                                        } items-center center border p-1 min-w-[60px] md:min-w-[70px]`}
                                    >
                                        <span className="text-[10px] md:text-[12px] text-[#333] whitespace-nowrap">{item.dot}</span>
                                        <strong className="text-[9px] md:text-[10px] text-[#333] whitespace-nowrap">
                                            {item.vatted_price_formatted ?? 0} TL
                                        </strong>
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        {product.products.length > 3 && (
                            <div className="absolute right-0 top-0 bottom-0 w-6 bg-gradient-to-l from-white to-transparent pointer-events-none"/>
                        )}
                    </div>

                    <div className="flex justify-end items-center mt-3">
                        {getItemQuantity({product_id: selectedProduct.id}) === 0 && (
                            <div className="grid grid-cols-2 gap-2 w-full">
                                <button
                                    onClick={() => navigate(`/product/${product.id}`)}
                                    className="flex items-center justify-center px-2 md:px-3 py-2 text-[10px] md:text-[12px] border border-blue-500 text-blue-500 bg-transparent rounded-[6px] hover:bg-blue-500 hover:text-white transition"
                                >
                                    <FontAwesomeIcon icon={faEye} className="mr-1 md:mr-2" />
                                    <span>İncele</span>
                                </button>
                                <AddToCartButton productId={selectedProduct.id} maxQuantity={selectedProduct.quantity} poolId={product.id} firmId={selectedProduct.firm_id} />
                            </div>
                        )}
                        {getItemQuantity({product_id: selectedProduct.id}) > 0 && (
                            <div className="w-full">
                                <AddToCartButton productId={selectedProduct.id} maxQuantity={selectedProduct.quantity} poolId={product.id} firmId={selectedProduct.firm_id} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;