// src/hooks/useProductFetcher.tsx
import { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { useFilter } from '../contexts/FilterContext';
import { ProductType } from '../types/types';

export const useProductFetcher = (city: string | null = null) => {
    const { filters } = useFilter();
    const [products, setProducts] = useState<ProductType[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    // Filtreler değiştiğinde sayfa numarasını 1'e sıfırla
    useEffect(() => {
        setPage(1);
    }, [filters, city]);

    const fetchProducts = async () => {
        setLoading(true);
        try {
            // Filtreleri kopyala
            const productFilters = { ...filters };
            
            // firm.city filtresini kaldır (bu servisler için)
            if (productFilters['filter[firm.city]']) {
                delete productFilters['filter[firm.city]'];
            }

            let params = {
                ...productFilters,
                include: 'products.firm',
                'filter[hasProducts]': true,
                page,
            };

            // Şehir varsa ekle - sadece products.firm.city parametresi kullanılmalı
            if(city) {
                params['filter[products.firm.city]'] = city;
                console.log('Şehir filtresi uygulanıyor (ürünler):', city);
            }

            console.log('API çağrısı parametreleri (ürünler):', params);
            const response = await axiosInstance.get('/ecommerce/products', { params });

            const newProducts: ProductType[] = response.data.data;
            setHasMore(page < response.data.meta.last_page);
            
            // Eğer sayfa 1 ise veya filtreler değiştiyse, tüm ürünleri yeni yanıtla değiştir
            // Aksi takdirde, mevcut ürünlere yeni ürünleri ekle
            setProducts((prev) => (page === 1 ? newProducts : [...prev, ...newProducts]));
        } catch (error) {
            setError('Ürünleri çekerken bir hata oluştu.');
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [filters, page, city]);

    const loadMore = () => setPage((prev) => prev + 1);

    return { products, loading, error, hasMore, loadMore };
};