import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import ClipLoader from 'react-spinners/ClipLoader';
import { ServiceType } from "../types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faMinus, faPlus, faArrowLeft, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../contexts/CartContext";
import { useCity } from '../contexts/CityContext';
import { useAuthMiddleware } from "../contexts/AuthMiddlewareContext";

const ServiceDetail: React.FC = () => {
    const { userData } = useAuthMiddleware();
    const { serviceId } = useParams();
    const navigate = useNavigate();
    const [service, setService] = useState<ServiceType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { addToCart, getItemQuantity, removeFromCart } = useCart();
    const { selectedCity } = useCity();
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
    const fallbackImage = 'https://panel.lastsis.com/media/default-images/service-default.jpeg';

    useEffect(() => {
        if (serviceId) {
            setLoading(true);
            axiosInstance
                .get(`/ecommerce/services/${serviceId}?city=${userData?.il}`)
                .then((response) => {
                    console.log('Service Detail API Response:', response.data.data);
                    setService(response.data.data);
                })
                .catch(() => setError('Hizmet bilgileri alınırken hata oluştu.'))
                .finally(() => setLoading(false));
        }
    }, [serviceId, userData?.il]);

    const handleAddToCart = (service: ServiceType) => {
        if (service?.firm?.id) {
            addToCart(service.firm.id, {
                service_id: service.id
            });
        }
    };

    const AddToCartButton: React.FC<{ service: ServiceType }> = ({ service }) => {
        const quantity = getItemQuantity({
            service_id: service.id
        });

        return (
            <div className="flex items-center space-x-2">
                {quantity > 0 ? (
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => removeFromCart({
                                service_id: service.id
                            })}
                            className="px-2 py-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition text-[10px]"
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className="text-md">{quantity}</span>
                        <button
                            onClick={() => handleAddToCart(service)}
                            className="px-2 py-1 bg-green-500 text-white rounded-full hover:bg-green-600 transition text-[10px]"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={() => handleAddToCart(service)}
                        className="px-2 py-1 bg-blue-500 text-white rounded-[6px] hover:bg-blue-600 transition text-[12px] w-100"
                    >
                        <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                        Ekle
                    </button>
                )}
            </div>
        );
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50} />
            </div>
        );
    }

    if (error || !service) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <p className="text-red-500 mb-4">{error || 'Hizmet bulunamadı'}</p>
                <button
                    onClick={() => navigate(-1)}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-300"
                >
                    Geri Dön
                </button>
            </div>
        );
    }

    const totalPrice = parseFloat(service.price) + parseFloat(service.total_vat);

    return (
        <div className="min-h-screen bg-white overflow-x-hidden">
            <div className="fixed top-0 left-0 right-0 bg-white shadow-md z-10">
                <button
                    onClick={() => navigate(-1)}
                    className="p-4 flex items-center text-gray-600"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    <span>Geri Dön</span>
                </button>
            </div>

            <div className="w-full max-w-full p-2 pt-20 pb-16 overflow-x-hidden">
                <div className="flex flex-col space-y-4">
                    <div className="bg-white rounded-lg p-4 shadow-sm">
                        <div className="flex flex-col md:flex-row md:items-start md:space-x-6">
                            <div className="relative w-full md:w-auto flex justify-center mb-4 md:mb-0">
                                <div className="relative">
                                    <img
                                        className="w-[200px] h-[200px] object-contain rounded-[5px]"
                                        alt={service.name}
                                        src={`https://lastsis.s3.amazonaws.com/lastsis/uploads/icons/${service.code}.jpg`}
                                        onError={(e) => {
                                            (e.target as HTMLImageElement).src = fallbackImage;
                                        }}
                                    />
                                    {service.code === "OTK-001" && (
                                        <div className="absolute bottom-0 left-0 right-0 bg-red-500 bg-opacity-80 text-white py-1.5 px-2">
                                            <p className="text-[11px] font-medium text-center">ÜCRETSİZ HİZMET</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="flex flex-col space-y-2">
                                    <h2 className="text-xl md:text-2xl font-bold">{service.name}</h2>
                                    <div className="flex flex-wrap gap-2">
                                        <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
                                            {service.category.name}
                                        </div>
                                        <div className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm font-medium">
                                            Hizmet Kodu: {service.code}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Açıklama Accordion */}
                    <div className="bg-white rounded-lg overflow-hidden shadow-sm">
                        <button
                            onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                            className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
                        >
                            <span className="font-medium text-gray-700">Hizmet Açıklaması</span>
                            <FontAwesomeIcon
                                icon={isDescriptionOpen ? faChevronUp : faChevronDown}
                                className="text-gray-500"
                            />
                        </button>
                        {isDescriptionOpen && (
                            <div className="p-4 text-gray-600 text-sm border-t space-y-4">
                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Genel Bilgiler</h3>
                                    <p className="leading-relaxed">
                                        {service.name} hizmeti, {service.category.name} kategorisinde profesyonel servis hizmetlerimiz arasında yer almaktadır. 
                                        Bu hizmet kapsamında, deneyimli teknisyenlerimiz tarafından en son teknoloji ekipmanlar kullanılarak işlemler gerçekleştirilmektedir. 
                                        Müşteri memnuniyeti ve güvenliği önceliğimiz olup, tüm işlemler sektör standartlarına uygun şekilde yapılmaktadır.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Hizmet Kapsamı</h3>
                                    <ul className="list-disc pl-4 space-y-2">
                                        <li>Modern ve kalibrasyon kontrolü yapılmış profesyonel ekipmanlarla hizmet</li>
                                        <li>Sertifikalı ve uzman teknisyenler tarafından titiz uygulama</li>
                                        <li>TSE ve uluslararası kalite standartlarına uygun işçilik</li>
                                        <li>İşçilik ve uygulama garantisi kapsamında hizmet</li>
                                        <li>Detaylı kontrol ve raporlama hizmeti</li>
                                        <li>Müşteri bilgilendirme ve danışmanlık desteği</li>
                                    </ul>
                                </div>

                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Önemli Notlar</h3>
                                    <ul className="list-disc pl-4 space-y-2 text-gray-600">
                                        <li>Hizmet süresi, araç tipine ve işlem detaylarına göre ortalama 30-45 dakika sürmektedir</li>
                                        <li>Daha iyi hizmet kalitesi için randevu sistemi ile çalışılmaktadır</li>
                                        <li>Belirtilen fiyatlara KDV dahildir</li>
                                        <li>Hizmet öncesi ve sonrası detaylı kontrol yapılmaktadır</li>
                                        <li>İşlem sonrası garanti belgesi düzenlenmektedir</li>
                                        <li>Periyodik bakım tavsiyeleri ve sonraki bakım tarihi bilgisi verilmektedir</li>
                                    </ul>
                                </div>

                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Kalite ve Güvence</h3>
                                    <p className="leading-relaxed text-gray-600">
                                        Tüm hizmetlerimiz, sektörün önde gelen standartlarına uygun olarak gerçekleştirilmektedir. 
                                        Kullanılan ekipmanlar düzenli olarak kalibre edilmekte ve bakımları yapılmaktadır. 
                                        Teknisyenlerimiz sürekli eğitimlerle bilgi ve becerilerini güncel tutmaktadır. 
                                        Müşteri memnuniyeti odaklı çalışma prensibiyle, işlem sonrası detaylı bilgilendirme ve takip hizmeti sunulmaktadır.
                                    </p>
                                </div>

                                {/* Bilgilendirme Notu */}
                                <div className="mt-6 bg-gray-50 p-4 rounded-lg text-left">
                                    <p className="text-[11px] text-gray-500">
                                        Bu açıklama metni <strong>LASTSIS</strong> tarafından <strong>Yapay Zeka</strong> kullanılarak oluşturulmuştur. 
                                        Hizmet detayları servis noktasına göre değişkenlik gösterebilir. 
                                        Kesin bilgi için seçtiğiniz servis ile iletişime geçmenizi öneririz.
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Satıcılar Bölümü */}
                    <div className="bg-white rounded-lg p-4 shadow-sm" id="sellers-section">
                        <h3 className="text-lg font-bold mb-4">Satıcılar</h3>
                        <div className="space-y-4">
                            {service.sellers && service.sellers.map((anotherService) => (
                                <div key={anotherService.id} className="flex flex-col border rounded-lg p-3 shadow-sm">
                                    <div className="flex items-center space-x-4 mb-3">
                                        <img
                                            src={anotherService?.firm?.logo ? `https://lastsis.s3.eu-central-1.amazonaws.com/lastsis/uploads/firms/${anotherService?.firm?.logo}` : 'https://panel.lastsis.com/favicon.ico'}
                                            alt={anotherService.name}
                                            className="w-12 h-12 object-contain rounded-full flex-shrink-0"
                                        />
                                        <div className="min-w-0 flex-1">
                                            <h3 className="text-md font-bold text-[13px] truncate">{anotherService?.firm?.title}</h3>
                                            <div className="flex items-center space-x-2">
                                                <p className="text-sm text-gray-500 text-[10px] truncate">
                                                    {anotherService?.firm?.city}, {anotherService?.firm?.county}
                                                </p>
                                                {anotherService?.firm?.city === selectedCity && (
                                                    <span className="bg-green-100 text-green-600 text-[10px] px-2 py-0.5 rounded-full">
                                                        Seçili İl
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="flex items-center justify-between mt-2">
                                        <div className="flex flex-col">
                                            <div className="text-[20px] font-bold text-blue-600">
                                                {(parseFloat(anotherService.price) + parseFloat(anotherService.total_vat)).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} TL
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <AddToCartButton service={anotherService} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Lastsis Logo ve Powered By */}
                    <div className="flex items-center justify-center space-x-2 mt-8 mb-4 pb-5">
                        <span className="text-gray-400 text-xs">Powered by</span>
                        <a 
                            href="https://www.lastsis.com" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <img 
                                src="/lastsis-logo.png" 
                                alt="Lastsis Logo" 
                                className="h-4 object-contain"
                                onError={(e) => {
                                    (e.target as HTMLImageElement).style.display = 'none';
                                }}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetail; 