import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useAuthMiddleware } from './AuthMiddlewareContext';

interface CityContextType {
    selectedCity: string;
    setSelectedCity: (city: string) => void;
}

const CityContext = createContext<CityContextType | undefined>(undefined);

export const CityProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { userData } = useAuthMiddleware();
    const [selectedCity, setSelectedCity] = useState<string>('');

    useEffect(() => {
        if (userData?.il) {
            setSelectedCity(userData.il);
        }
    }, [userData?.il]);

    return (
        <CityContext.Provider value={{ selectedCity, setSelectedCity }}>
            {children}
        </CityContext.Provider>
    );
};

export const useCity = () => {
    const context = useContext(CityContext);
    if (!context) {
        throw new Error('useCity must be used within a CityProvider');
    }
    return context;
};

export default CityContext; 