import React, { useState } from 'react';
import { useFilter } from "../contexts/FilterContext";
import { useCity } from "../contexts/CityContext";
import CitySelector from './CitySelector';

const ServiceFilter: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { applyFilters } = useFilter();
    const { selectedCity, setSelectedCity } = useCity();
    const [search, setSearch] = useState('');
    const [tempSelectedCity, setTempSelectedCity] = useState(selectedCity);

    const handleSubmit = () => {
        const newFilters: Record<string, string> = {};
        if (search) newFilters['filter[search]'] = search;
        
        // Şehir seçimini "firm.city" parametresi olarak filtrelere ekliyoruz
        if (tempSelectedCity) {
            newFilters['filter[firm.city]'] = tempSelectedCity;
            console.log('Şehir filtresi uygulandı:', tempSelectedCity);
        }
        
        // Önce şehri güncelle
        setSelectedCity(tempSelectedCity);
        
        // Sonra filtreleri uygula
        applyFilters(newFilters);
        console.log('Uygulanan filtreler:', newFilters);
        
        // Menüyü kapat
        setIsMenuOpen(false);
    };

    const customInputStyle = {
        border: 'none',
        outline: 'none',
        width: '100%',
        padding: '6px 10px',
        lineHeight: '24px',
        fontSize: '12px',
        backgroundColor: 'transparent'
    };

    return (
        <div className="flex flex-row w-100 justify-between py-1 space-x-2" style={{ width: '100%'}}>
            {/* Filtrele Butonu */}
            <button
                onClick={() => setIsMenuOpen(true)}
                className="px-4 py-1.5 rounded-[6px] bg-gray-200 text-black border border-gray-300 flex flex-row items-center w-[120px] justify-center"
            >
                <img src="/filtrele.png" className={`w-[18px] mr-2`} alt=""/>
                <span className="text-[13px]">Filtrele</span>
            </button>
            <div className={`flex flex-row flex-1`}>
                <div className="flex flex-1">
                    <input
                        type="text"
                        placeholder="Hizmet adı ile arama yapın..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className={`flex-1 border border-gray-300 ${customInputStyle}`} 
                        style={{
                            padding: '6px 10px',
                            lineHeight: '24px',
                            border: '1px solid rgb(209, 213, 219)',
                            backgroundColor: 'rgb(229, 231, 235)',
                            borderTopRightRadius:0,
                            borderBottomRightRadius:0,
                            fontSize: '12px'
                        }}
                    />
                    <button
                        onClick={handleSubmit}
                        className="w-[60px] bg-gray-700 text-white p-1 text-[14px] hover:bg-blue-600 transition"
                        style={{
                            borderTopLeftRadius:0,
                            borderBottomLeftRadius:0,
                            borderTopRightRadius: '6px',
                            borderBottomRightRadius: '6px',
                        }}
                    >
                        Ara
                    </button>
                </div>
            </div>

            {isMenuOpen && (
                <>
                    {/* Sağdaki opak alan, menüyü kapatmak için */}
                    <div
                        className="fixed inset-0 bg-black opacity-70 z-40"
                        onClick={() => setIsMenuOpen(false)}
                    ></div>

                    {/* Filtre Menüsü (Sola Yaslanmış) */}
                    <div className="fixed inset-y-0 left-0 w-4/5 bg-white p-6 z-50 overflow-auto shadow-lg">
                        <button
                            className="absolute top-4 right-4 h-8 w-8 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 transition shadow-md"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <span className="text-gray-600">×</span>
                        </button>

                        <input
                            type="text"
                            placeholder="Hizmet adı ile arama yapın..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className={`w-full mb-4 mt-10 ${customInputStyle}`}
                        />

                        <h5 className={`text-[14px] font-bold`}>İl</h5>
                        <div className="mb-4">
                            <CitySelector
                                value={tempSelectedCity}
                                onChange={setTempSelectedCity}
                                showLabel={false}
                            />
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="w-full bg-gray-700 text-white p-3 rounded-md hover:bg-blue-600 transition mt-4"
                        >
                            Arama Yap
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ServiceFilter; 