import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import ClipLoader from 'react-spinners/ClipLoader';
import {ProductType, SubProductType} from "../types/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartPlus, 
    faMinus, 
    faPlus, 
    faArrowLeft,
    faSun,
    faSnowflake,
    faCloudMoonRain,
    faGasPump,
    faCloudShowersHeavy,
    faVolumeUp,
    faChevronDown,
    faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import {useCart} from "../contexts/CartContext";
import { useCity } from '../contexts/CityContext';
import Filter from '../components/Filter';
import { useFilter } from '../contexts/FilterContext';

const formatCurrency = (amount: number) =>
    new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', minimumFractionDigits: 2 })
        .format(amount)
        .replace('₺', '')

const ProductDetail: React.FC = () => {
    const { selectedCity } = useCity();
    const { filters, applyFilters } = useFilter();
    const { productId } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState<ProductType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [maxQuantity, setMaxQuantity] = useState<number>(0);
    const { addToCart, getItemQuantity, removeFromCart } = useCart();
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
    const [isTechnicalOpen, setIsTechnicalOpen] = useState(false);

    const fallbackImage = 'https://panel.lastsis.com/media/default-images/tire-default.jpeg';

    useEffect(() => {
        if (productId) {
            setLoading(true);
            axiosInstance
                .get(`/ecommerce/products/${productId}`)
                .then((response) => {
                    console.log('Product Detail API Response:', response.data.data);
                    setProduct(response.data.data);
                })
                .catch(() => setError('Satıcı bilgileri alınırken hata oluştu.'))
                .finally(() => setLoading(false));
        }
    }, [productId]);

    useEffect(() => {
        // URL'den scrollTo parametresini al
        const urlParams = new URLSearchParams(window.location.search);
        const scrollTo = urlParams.get('scrollTo');

        // Eğer scrollTo parametresi 'sellers' ise ve sayfa yüklendiyse
        if (scrollTo === 'sellers' && !loading) {
            // Satıcılar bölümüne scroll yap
            const sellersSection = document.getElementById('sellers-section');
            if (sellersSection) {
                sellersSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [loading]);

    const handleAddToCart = (seller: SubProductType) => {
        let quantity = getItemQuantity({
            product_id: seller.id
        });
        if (quantity < seller.quantity) {
            addToCart(seller?.firm.id, {
                product_id: seller.id
            });
        } else {
            alert('Stokta yeterli ürün yok.');
        }
    };

    const AddToCartButton: React.FC<{ seller: SubProductType }> = ({ seller }) => {
        const quantity = getItemQuantity({
            product_id: seller.id
        });
        setMaxQuantity(seller.quantity);

        return (
            <div className="flex items-center space-x-2">
                {quantity > 0 ? (
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => removeFromCart({
                                product_id: seller.id
                            })}
                            className="px-2 py-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition text-[10px]"
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className="text-md">{quantity}</span>
                        <button
                            onClick={() => handleAddToCart(seller)}
                            className="px-2 py-1 bg-green-500 text-white rounded-full hover:bg-green-600 transition text-[10px]"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={() => handleAddToCart(seller)}
                        className="px-2 py-1 bg-blue-500 text-white rounded-[6px] hover:bg-blue-600 transition text-[12px]"
                    >
                        <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                        Ekle
                    </button>
                )}
            </div>
        );
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50} />
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center p-4">{error}</div>;
    }

    return (
        <div className="min-h-screen bg-white overflow-x-hidden">
            <div className="fixed top-0 left-0 right-0 bg-white shadow-md z-10">
                <button
                    onClick={() => navigate(-1)}
                    className="p-4 flex items-center text-gray-600"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    <span>Geri Dön</span>
                </button>
            </div>

            <div className="w-full max-w-full p-2 pt-20 pb-16 overflow-x-hidden">
                <div className="flex flex-col space-y-4">
                    <div className="bg-white rounded-lg p-4 shadow-sm">
                        <div className="flex flex-col md:flex-row md:items-start md:space-x-6">
                            <div className="relative w-full md:w-auto flex justify-center mb-4 md:mb-0">
                                <img
                                    className="w-[200px] h-[200px] object-contain"
                                    alt={product?.name}
                                    src={`https://d2buqeq24qhiv5.cloudfront.net/250x250/pool/${product?.real_image}`}
                                    onError={(e) => {
                                        (e.target as HTMLImageElement).src = fallbackImage;
                                    }}
                                />
                                <div className="absolute top-0 left-0 p-2">
                                    {product?.season && (
                                        <div className="text-2xl">
                                            {product.season.includes('Yaz') ? (
                                                <img
                                                    src="/summer.png"
                                                    alt="Yaz Lastiği"
                                                    className="h-7 w-7 object-contain"
                                                    style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                                    title="Yaz Lastiği"
                                                />
                                            ) : product.season.includes('Kış') ? (
                                                <img
                                                    src="/winter.png"
                                                    alt="Kış Lastiği"
                                                    className="h-7 w-7 object-contain"
                                                    style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                                    title="Kış Lastiği"
                                                />
                                            ) : (
                                                <img
                                                    src="/allseason.png"
                                                    alt="Dört Mevsim Lastiği"
                                                    className="h-7 w-7 object-contain"
                                                    style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                                    title="Dört Mevsim Lastiği"
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="flex flex-col space-y-2">
                                    <h2 className="text-xl md:text-2xl font-bold">{product?.name}</h2>
                                    <div className="flex flex-wrap gap-2">
                                        <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
                                            {product?.category}
                                        </div>
                                        <div className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm font-medium">
                                            Ürün Kodu: {product?.stock_code}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-3 mt-4">
                            <div className="flex flex-col items-center bg-gray-50 p-2 rounded-lg">
                                <FontAwesomeIcon 
                                    icon={faGasPump} 
                                    className="text-yellow-500 text-xl mb-1"
                                    style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                />
                                <span className="text-base font-medium">{product?.energy_class}</span>
                                <span className="text-[10px] text-gray-500 text-center">Yakıt</span>
                            </div>
                            <div className="flex flex-col items-center bg-gray-50 p-2 rounded-lg">
                                <FontAwesomeIcon 
                                    icon={faCloudShowersHeavy} 
                                    className="text-blue-500 text-xl mb-1"
                                    style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                />
                                <span className="text-base font-medium">{product?.brake_performance}</span>
                                <span className="text-[10px] text-gray-500 text-center">Islak Zemin</span>
                            </div>
                            <div className="flex flex-col items-center bg-gray-50 p-2 rounded-lg">
                                <FontAwesomeIcon 
                                    icon={faVolumeUp} 
                                    className="text-red-500 text-xl mb-1"
                                    style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                />
                                <span className="text-base font-medium">{product?.noise_level}</span>
                                <span className="text-[10px] text-gray-500 text-center">Ses (dB)</span>
                            </div>
                        </div>
                    </div>

                    {/* Teknik Özellikler Accordion */}
                    <div className="bg-white rounded-lg overflow-hidden">
                        <button
                            onClick={() => setIsTechnicalOpen(!isTechnicalOpen)}
                            className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
                        >
                            <span className="font-medium text-gray-700">Teknik Özellikler</span>
                            <FontAwesomeIcon
                                icon={isTechnicalOpen ? faChevronUp : faChevronDown}
                                className="text-gray-500"
                            />
                        </button>
                        {isTechnicalOpen && (
                            <div className="p-4">
                                <table className="w-full">
                                    <tbody>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Marka</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.brand}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Desen</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.pattern}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Ebat</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.size}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Mevsim</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.season}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Yük Endeksi</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.yuk}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Hız Endeksi</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.hiz}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Yakıt Verimliliği</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.energy_class}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Islak Zemin</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.brake_performance}</td>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-200 px-3 py-1.5 text-left bg-gray-50 text-gray-600 font-medium text-sm">Ses Seviyesi</th>
                                            <td className="border border-gray-200 px-3 py-1.5 text-gray-600 text-sm">{product?.noise_level}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                    {/* Açıklama Accordion */}
                    <div className="bg-white rounded-lg overflow-hidden">
                        <button
                            onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                            className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
                        >
                            <span className="font-medium text-gray-700">Ürün Açıklaması</span>
                            <FontAwesomeIcon
                                icon={isDescriptionOpen ? faChevronUp : faChevronDown}
                                className="text-gray-500"
                            />
                        </button>
                        {isDescriptionOpen && (
                            <div className="p-4 text-gray-600 text-sm border-t space-y-4">
                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Genel Özellikler</h3>
                                    <p className="leading-relaxed">
                                        {product?.brand} markalı {product?.name} lastik modeli, {product?.size} ebatında bir {product?.season?.toLowerCase()} lastiğidir. 
                                        {product?.category} kategorisinde yer alan bu lastik, {product?.pattern} desen yapısı ile dikkat çekmektedir.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Teknik Özellikler</h3>
                                    <div className="space-y-2">
                                        <div className="flex items-start space-x-2">
                                            <p>
                                                <span className="font-medium">Yük Endeksi: </span>
                                                {getYukEndeksiDescription(product?.yuk)}
                                            </p>
                                        </div>
                                        <div className="flex items-start space-x-2">
                                            <p>
                                                <span className="font-medium">Hız Endeksi: </span>
                                                {getHizEndeksiDescription(product?.hiz)}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Performans Özellikleri</h3>
                                    <div className="space-y-2">
                                        <div className="flex items-start space-x-2">
                                            <FontAwesomeIcon 
                                                icon={faGasPump} 
                                                className="text-yellow-500 mt-1"
                                                style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                            />
                                            <p>
                                                <span className="font-medium">Yakıt Verimliliği ({product?.energy_class}): </span>
                                                {getEnergyDescription(product?.energy_class)}
                                            </p>
                                        </div>
                                        <div className="flex items-start space-x-2">
                                            <FontAwesomeIcon 
                                                icon={faCloudShowersHeavy} 
                                                className="text-blue-500 mt-1"
                                                style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                            />
                                            <p>
                                                <span className="font-medium">Islak Zemin Performansı ({product?.brake_performance}): </span>
                                                {getBrakeDescription(product?.brake_performance)}
                                            </p>
                                        </div>
                                        <div className="flex items-start space-x-2">
                                            <FontAwesomeIcon 
                                                icon={faVolumeUp} 
                                                className="text-red-500 mt-1"
                                                style={{ filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))' }}
                                            />
                                            <p>
                                                <span className="font-medium">Ses Seviyesi ({product?.noise_level} dB): </span>
                                                {getNoiseDescription(product?.noise_level)}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className="font-medium text-gray-800 mb-2">Kullanım Önerileri</h3>
                                    <p className="leading-relaxed">
                                        {getSeasonalAdvice(product?.season)}
                                    </p>
                                </div>

                                {product?.category === '4x4 & SUV' && (
                                    <div>
                                        <h3 className="font-medium text-gray-800 mb-2">Arazi Kullanımı</h3>
                                        <p className="leading-relaxed">
                                            4x4 ve SUV araçlar için özel olarak tasarlanan bu lastik, hem şehir içi kullanımda hem de hafif arazi koşullarında yüksek performans sunar. 
                                            {product?.pattern} desen yapısı, zorlu yol koşullarında üstün çekiş ve kontrol sağlar.
                                        </p>
                                    </div>
                                )}

                                {/* Bilgilendirme Notu */}
                                <div className="mt-6 bg-gray-50 p-4 rounded-lg text-left">
                                    <p className="text-[11px] text-gray-600">
                                        Bu açıklama metni <strong>LASTSIS</strong> tarafından <strong>Yapay Zeka</strong> kullanılarak oluşturulmuş olup yalnızca bilgilendirme amaçlıdır. 
                                        Olası hatalardan sorumlu değiliz; kesin ve güvenilir bilgi için uzman görüşü almanız tavsiye edilir.
                                    </p>
                                </div>

                                {/* Filtreleri Sıfırla Butonu */}
                                {Object.values(filters).some((value) => value) && (
                                    <div className="flex justify-center mt-4 pt-4 border-t">
                                        <button
                                            onClick={() => {
                                                applyFilters({});
                                                navigate('/');
                                            }}
                                            className="px-4 py-2 bg-red-500 bg-opacity-10 text-red-500 text-sm rounded-md transition hover:bg-opacity-20"
                                        >
                                            Filtreleri Sıfırla ve Ana Sayfaya Dön
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Satıcılar Bölümü */}
                    <div className="bg-white rounded-lg p-4 shadow-sm" id="sellers-section">
                        <h3 className="text-lg font-bold mb-4">Satıcılar</h3>
                        <div className="space-y-4">
                            {product?.all_products && product.all_products.map((seller) => (
                                <div key={seller.id} className="flex flex-col border rounded-lg p-3 shadow-sm">
                                    <div className="flex items-center space-x-4 mb-3">
                                        <img
                                            src={seller?.firm?.logo ? `https://lastsis.s3.eu-central-1.amazonaws.com/lastsis/uploads/firms/${seller?.firm?.logo}` : 'https://panel.lastsis.com/favicon.ico'}
                                            alt={seller.title}
                                            className="w-12 h-12 object-contain rounded-full flex-shrink-0"
                                        />
                                        <div className="min-w-0 flex-1">
                                            <h3 className="text-md font-bold text-[13px] truncate">{seller?.firm?.title}</h3>
                                            <div className="flex items-center space-x-2">
                                                <p className="text-sm text-gray-500 text-[10px] truncate">
                                                    {seller?.firm?.city}, {seller?.firm?.county}
                                                </p>
                                                {seller?.firm?.city === selectedCity && (
                                                    <span className="bg-green-100 text-green-600 text-[10px] px-2 py-0.5 rounded-full">
                                                        Seçili İl
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`px-3 py-2 rounded-[6px] text-[14px] bg-red-500 bg-opacity-10 text-red-500 font-bold`}>
                                            {seller.dot ?? '-'}
                                        </div>
                                    </div>
                                    
                                    <div className="flex items-center justify-between mt-2">
                                        <div className="flex flex-col">
                                            <div className="text-[20px] font-bold text-blue-600">
                                                {formatCurrency(parseFloat(seller.price) + parseFloat(seller.total_vat))} ₺
                                            </div>
                                            <div className="text-[11px] text-gray-500">
                                                Stok: {seller.quantity > 20 ? '20+' : seller.quantity} adet
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <AddToCartButton seller={seller} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Lastsis Logo ve Powered By */}
                    <div className="flex items-center justify-center space-x-2 mt-8 mb-4 pb-5">
                        <span className="text-gray-400 text-xs">Powered by</span>
                        <a 
                            href="https://www.lastsis.com" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <img 
                                src="/lastsis-logo.png" 
                                alt="Lastsis Logo" 
                                className="h-4 object-contain"
                                onError={(e) => {
                                    (e.target as HTMLImageElement).style.display = 'none';
                                }}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Yardımcı fonksiyonlar (component dışında tanımlanacak)
const getEnergyDescription = (energyClass?: string) => {
    switch (energyClass) {
        case 'A':
        case 'B':
            return 'Yüksek yakıt verimliliği sunar, düşük yuvarlanma direnci ile yakıt tüketimini optimize eder.';
        case 'C':
        case 'D':
            return 'Ortalama yakıt verimliliği sağlar, dengeli bir yuvarlanma direnci sunar.';
        case 'E':
        case 'F':
            return 'Standart yakıt verimliliği değerlerine sahiptir.';
        default:
            return 'Yakıt verimliliği sınıfı belirtilmemiştir.';
    }
};

const getBrakeDescription = (brakeClass?: string) => {
    switch (brakeClass) {
        case 'A':
            return 'En üst düzey ıslak zemin fren performansı sunar, yağışlı havalarda maksimum güvenlik sağlar.';
        case 'B':
            return 'Yüksek ıslak zemin fren performansı ile güvenli sürüş imkanı sunar.';
        case 'C':
            return 'Ortalama ıslak zemin fren performansına sahiptir, yağışlı havalarda dikkatli kullanım önerilir.';
        default:
            return 'Islak zemin fren performansı sınıfı belirtilmemiştir.';
    }
};

const getNoiseDescription = (noiseLevel?: string) => {
    const level = parseInt(noiseLevel || '0');
    if (level <= 68) return 'Çok düşük ses seviyesi ile konforlu bir sürüş deneyimi sunar.';
    if (level <= 72) return 'Normal ses seviyesine sahiptir, sürüş konforu için uygundur.';
    return 'Standart ses seviyesi değerlerine sahiptir.';
};

const getSeasonalAdvice = (season?: string) => {
    if (season?.includes('Yaz')) {
        return 'Yaz lastiği olarak tasarlanan bu model, sıcak hava koşullarında optimum performans gösterir. İdeal kullanım sıcaklığı 7°C ve üzeridir. Kış aylarında kış lastiği kullanımı önerilir.';
    }
    if (season?.includes('Kış')) {
        return 'Kış koşulları için özel olarak geliştirilen bu lastik, 7°C altındaki sıcaklıklarda optimum performans gösterir. Kar ve buz koşullarında güvenli sürüş sağlar.';
    }
    return 'Dört mevsim kullanıma uygun olan bu lastik, yıl boyunca dengeli performans sunar. Ancak ekstrem kış koşullarında kış lastiği kullanımı önerilir.';
};

const getYukEndeksiDescription = (yukEndeksi?: string) => {
    if (!yukEndeksi) return 'Yük endeksi belirtilmemiştir.';
    
    const yukValue = parseInt(yukEndeksi);
    let maxKg = Math.floor(Math.pow(Math.E, ((yukValue - 45) / 32.2))) * 45;
    
    return `Yük endeksi ${yukEndeksi} olan bu lastik, tek başına ${maxKg} kg'a kadar yük taşıma kapasitesine sahiptir. Bu değer, lastiğin güvenli bir şekilde taşıyabileceği maksimum ağırlığı gösterir.`;
};

const getHizEndeksiDescription = (hizEndeksi?: string) => {
    const hizEndeksleri: { [key: string]: number } = {
        'L': 120, 'M': 130, 'N': 140, 'P': 150, 'Q': 160,
        'R': 170, 'S': 180, 'T': 190, 'U': 200, 'H': 210,
        'V': 240, 'W': 270, 'Y': 300, 'ZR': 240
    };

    if (!hizEndeksi || !hizEndeksleri[hizEndeksi]) {
        return 'Hız endeksi belirtilmemiştir.';
    }

    return `Hız endeksi ${hizEndeksi} olan bu lastik, saatte maksimum ${hizEndeksleri[hizEndeksi]} km hıza kadar güvenli kullanım sağlar. Bu değer, lastiğin dayanabileceği maksimum hızı belirtir.`;
};

export default ProductDetail; 