// src/components/AddToCartButton.tsx
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCartPlus, faExclamationCircle, faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../contexts/CartContext';
// @ts-ignore
import Modal from 'react-modal';
import {useProductPopup} from "../contexts/ProductPopupContext";

interface AddToCartButtonProps {
    productId: number;
    poolId: number;
    maxQuantity: number;
    firmId: number;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({ productId, poolId, maxQuantity , firmId}) => {
    const { addToCart, removeFromCart, getItemQuantity } = useCart();
    const { openPopup } = useProductPopup();
    const quantity = getItemQuantity({
        product_id: productId
    });

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isWarningOpen, setIsWarningOpen] = useState(false);

    const handleAddToCart = () => {
        if (quantity < maxQuantity) {
            addToCart(firmId, {
                product_id: productId
            });
        } else {
            setIsWarningOpen(true);
        }
    };

    const customModalStyles: Modal.Styles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1000,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            padding: '30px',
            width: '400px',
            textAlign: 'center',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        },
    };

    return (
        <div className="flex items-center space-x-2">
            {quantity > 0 ? (
                <div className="flex items-center justify-between bg-gray-100 rounded-lg w-full">
                    <button
                        onClick={() => removeFromCart({
                            product_id: productId
                        })}
                        className="w-8 h-8 flex items-center justify-center bg-red-500 text-white rounded-l-lg hover:bg-red-600 transition"
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <div className="px-3">
                        <span className="text-lg font-semibold min-w-[30px] text-center">{quantity}</span>
                    </div>
                    <button
                        onClick={() => handleAddToCart()}
                        className="w-8 h-8 flex items-center justify-center bg-green-500 text-white rounded-r-lg hover:bg-green-600 transition"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            ) : (
                <button
                    onClick={() => handleAddToCart()}
                    className="w-full flex items-center justify-center px-3 py-2 bg-blue-500 text-white rounded-[6px] hover:bg-blue-600 transition text-[12px]"
                >
                    <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                    Ekle
                </button>
            )}
            <Modal
                isOpen={isWarningOpen}
                onRequestClose={() => setIsWarningOpen(false)}
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        padding: '30px',
                        width: '400px',
                        height: '300px',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 1000 },
                }}
            >
                <div className="mb-4">
                    <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500" size="3x" />
                </div>
                <h2 className="text-lg font-bold mb-4">Uyarı</h2>
                <p className="mb-4 text-sm text-gray-700">
                    İlgili ürün, satıcıda {maxQuantity} adet bulunmaktadır.
                    Diğer satıcılara bakarak istediğiniz adette alım gerçekleştirebilirsiniz.
                </p>
                <div className="flex">
                    <button
                        onClick={() => setIsWarningOpen(false)}
                        className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition mr-4"
                    >
                        Vazgeç
                    </button>
                    <button
                        onClick={() => {
                            setIsWarningOpen(false);
                            setIsPopupOpen(true);
                            openPopup(poolId);
                        }}
                        className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                    >
                        Diğer Satıcıları Görüntüle
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AddToCartButton;