import React, {useEffect, useState} from 'react';
// @ts-ignore
import MaskedInput from 'react-input-mask';
import {useCart} from "../contexts/CartContext";
import {useForm} from "../contexts/FormContext";
import CardMockup from "./CardMockup";
import axiosInstance from "../axiosInstance";
import {BasketProductType, BasketServiceType} from "../types/types";
import ClipLoader from 'react-spinners/ClipLoader';
// @ts-ignore
import Modal from 'react-modal';
import {useNavigate} from "react-router-dom";
import {useAuthMiddleware} from "../contexts/AuthMiddlewareContext";
import PaymentMethodSelector, { PaymentMethod } from './PaymentMethodSelector';
import BankTransferInfo from './BankTransferInfo';

const customInputStyle =
    "bg-[#999999] bg-opacity-10 rounded-md focus:outline-none leading-[44px] px-[15px] border-0 placeholder-[#999999] text-[14px]";

interface PaymentFormProps {
    onSave: (paymentData: any) => void;
}

const formatCurrency = (amount: number) =>
    new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', minimumFractionDigits: 2 })
        .format(amount)
        .replace('₺', '') // TRY sembolünü kaldırmak isterseniz

const PaymentForm: React.FC<PaymentFormProps> = ({ onSave }) => {
    const { cartItems, getItemQuantity, clearCart } = useCart();
    const { payment, updatePayment } = useForm();
    const navigate = useNavigate();
    const { userData } = useAuthMiddleware();

    const [products, setProducts] = useState<BasketProductType[]>([]);
    const [services, setServices] = useState<BasketServiceType[]>([]);
    const [loading, setLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('credit_card');

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [showCartPopup, setShowCartPopup] = useState(false);
    const togglePopup = () => setShowCartPopup(!showCartPopup);

    const [isProcessing, setIsProcessing] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [acceptTerms, setAcceptTerms] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        updatePayment({ [name]: value });
    };

    const validatePayment = () => {
        if (paymentMethod === 'bank_transfer') {
            return {};
        }

        const validationErrors: Record<string, string> = {};

        if (!payment.card_number || payment.card_number.replace(/\s+/g, '').length !== 16) {
            validationErrors.cardNumber = 'Geçerli bir kart numarası girin.';
        }

        if (!payment.card_date || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(payment.card_date)) {
            validationErrors.expiryDate = 'Geçerli bir son kullanım tarihi girin (AA/YY).';
        }

        if (!payment.card_cvv || payment.card_cvv.length !== 3) {
            validationErrors.cvv = 'CVV 3 haneli olmalıdır.';
        }

        return validationErrors;
    };

    useEffect(() => {
        const fetchBasketProducts = async () => {
            try {
                if (cartItems.length === 0) {
                    setProducts([]);
                    setLoading(false);
                    return;
                }

                const productIds = cartItems.filter(item => item.product_id).map((item) => item.product_id).join(',');
                if(productIds.length > 0) {
                    const response = await axiosInstance.get(`/ecommerce/basket`, {
                        params: { 'filter[id]': productIds, 'include': 'pool,firm' },
                    });
                    setProducts(response.data.data);
                }else{
                    setProducts([]);
                }
            } catch (error) {
                //setError('Sepet ürünleri yüklenirken hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchBasketProducts();
    }, [cartItems]);

    useEffect(() => {
        const fetchBasketServices = async () => {
            try {
                if (cartItems.length === 0) {
                    setProducts([]);
                    setLoading(false);
                    return;
                }

                const serviceIds = cartItems.filter(item => item.service_id).map((item) => item.service_id).join(',');
                if(serviceIds.length > 0) {
                    const response = await axiosInstance.get(`/ecommerce/basket/services`, {
                        params: { 'filter[id]': serviceIds, 'include': 'category,firm' },
                    });
                    setServices(response.data.data);
                }else{
                    setServices([]);
                }
            } catch (error) {
                //setError('Sepet ürünleri yüklenirken hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchBasketServices();
    }, [cartItems]);

    const totalProductAmount = products.reduce(
        (acc, product) => acc + (parseFloat(product.price) + parseFloat(product.total_vat)) * getItemQuantity({
            product_id: product.id
        }),
        0
    );

    const totalServiceAmount = services.reduce(
        (acc, service) => acc + (parseFloat(service.price) + parseFloat(service.total_vat)) * getItemQuantity({
            service_id: service.id
        }),
        0
    );

    const totalAmount = totalProductAmount + totalServiceAmount;

    const handlePaymentSubmit = () => {
        if (paymentMethod === 'bank_transfer' && !acceptTerms) {
            setErrorMessage('Lütfen satış koşullarını kabul ediniz.');
            setShowErrorModal(true);
            return;
        }

        const validationErrors = validatePayment();
        if (Object.keys(validationErrors).length === 0) {
            setIsProcessing(true);

            const orderData = {
                customer: {
                    reference: userData?.id,
                    type: 'private',
                    name: userData.firstname,
                    surname: userData.lastname,
                    email: userData.email,
                    phone: userData.telefon
                },
                vehicle: {
                    plate: userData.plaka,
                },
                cart: cartItems,
                payment_method: paymentMethod
            };

            if (paymentMethod === 'credit_card') {
                Object.assign(orderData, payment);
            }

            axiosInstance.post('/ecommerce/order', orderData)
                .then((response) => {
                    if(!response.data.status){
                        if(response.data.form3d){
                            window.location.href = response.data.form3d;
                        } else {
                            setErrorMessage(response.data.all.message || 'Ödeme işlemi başarısız oldu.');
                            setShowErrorModal(true);
                        }
                    } else {
                        setShowSuccessModal(true);
                        clearCart();
                    }
                })
                .catch((error) => {
                    setErrorMessage(error.response?.data?.all?.message || 'Ödeme işlemi sırasında bir hata oluştu.');
                    setShowErrorModal(true);
                })
                .finally(() => {
                    setIsProcessing(false);
                });

            setErrors({});
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <div className="flex flex-col mb-20">
            {isProcessing && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white p-2 rounded-full">
                        <ClipLoader color="#3498db" size={50} />
                    </div>
                    <span className="text-white ml-2 font-medium">Ödeme işleniyor...</span>
                </div>
            )}

            <div className="space-y-2 mt-2 bg-white p-2 rounded-[6px] shadow-sm pb-24">
                <PaymentMethodSelector
                    selectedMethod={paymentMethod}
                    onMethodChange={setPaymentMethod}
                />

                <div className="mt-10">
                    {paymentMethod === 'credit_card' ? (
                        <>
                            <CardMockup />

                            <div className="mt-10 space-y-4">
                                <input
                                    type="text"
                                    name="card_name"
                                    placeholder="Kart Sahibi"
                                    value={payment.card_name}
                                    className={`w-full ${customInputStyle}`}
                                    onChange={handleInputChange}
                                />
                                {errors.card_name && <p className="text-red-500 text-sm">{errors.card_name}</p>}

                                <MaskedInput
                                    mask="9999 9999 9999 9999"
                                    name="card_number"
                                    placeholder="Kart Numarası"
                                    value={payment.card_number}
                                    className={`w-full ${customInputStyle}`}
                                    onChange={handleInputChange}
                                />
                                {errors.cardNumber && <p className="text-red-500 text-sm">{errors.cardNumber}</p>}

                                <MaskedInput
                                    mask="99/99"
                                    name="card_date"
                                    placeholder="Son Kullanma Tarihi (AA/YY)"
                                    value={payment.card_date}
                                    className={`w-full ${customInputStyle}`}
                                    onChange={handleInputChange}
                                />
                                {errors.expiryDate && <p className="text-red-500 text-sm">{errors.expiryDate}</p>}

                                <input
                                    type="text"
                                    name="card_cvv"
                                    placeholder="CVV"
                                    value={payment.card_cvv}
                                    className={`w-full ${customInputStyle}`}
                                    onChange={handleInputChange}
                                    maxLength={3}
                                />
                                {errors.cvv && <p className="text-red-500 text-sm">{errors.cvv}</p>}
                            </div>
                        </>
                    ) : (
                        <div className="space-y-2">
                            <div className="bg-red-50 border border-red-100 rounded-lg p-2 text-sm text-red-700 mb-2">
                                <h4 className="font-semibold mb-1">Önemli Not:</h4>
                                <p>Satın almak üzere olduğunuz Ürün ve Hizmetler {products[0]?.firm?.title} tarafından {products[0]?.firm?.city}/{products[0]?.firm?.county} adresinde serviste tamamlanacaktır. OTOKONFOR Satış yönteminde Kargo ile ürün gönderme hizmeti yoktur.</p>
                            </div>

                            <div className="flex items-start space-x-2 mb-2">
                                <input
                                    type="checkbox"
                                    id="acceptTerms"
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                    className="mt-1"
                                />
                                <label htmlFor="acceptTerms" className="text-sm text-gray-600">
                                    Yukarıdaki satış koşullarını okudum ve kabul ediyorum.
                                </label>
                            </div>

                            <BankTransferInfo
                                orderNumber={Math.random().toString(36).substr(2, 9).toUpperCase()}
                                totalAmount={totalAmount}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="fixed bottom-[60px] left-0 w-full bg-white shadow-md p-4 flex items-center justify-between border-t z-[999]">
                <div className="flex flex-row items-center" onClick={togglePopup}>
                    <h2 className="font-bold text-[14px]">Toplam Tutar: {formatCurrency(totalAmount)} TL</h2>
                </div>

                <button
                    onClick={handlePaymentSubmit}
                    className="bg-blue-500 text-white px-6 py-2 rounded-[6px] hover:bg-blue-600 transition"
                    disabled={isProcessing || (paymentMethod === 'bank_transfer' && !acceptTerms)}
                >
                    {paymentMethod === 'credit_card' ? 'Ödemeyi Tamamla' : 'Siparişi Oluştur'}
                </button>
            </div>

            <Modal
                isOpen={showSuccessModal}
                onRequestClose={() => {
                    setShowSuccessModal(false);
                    navigate('/orders');
                }}
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="bg-white p-6 rounded-lg max-w-md mx-auto">
                    <h2 className="text-xl font-bold mb-4">Siparişiniz Başarıyla Oluşturuldu!</h2>
                    <p className="text-gray-600 mb-6">
                        {paymentMethod === 'credit_card'
                            ? 'Ödemeniz başarıyla alındı. Siparişiniz en kısa sürede işleme alınacaktır.'
                            : 'Siparişiniz oluşturuldu. Lütfen 1 iş günü içerisinde ödemenizi yapınız. Aksi takdirde siparişiniz otomatik olarak iptal edilecektir.'}
                    </p>
                    <button
                        onClick={() => {
                            setShowSuccessModal(false);
                            navigate('/orders');
                        }}
                        className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
                    >
                        Siparişlerime Git
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={showErrorModal}
                onRequestClose={() => setShowErrorModal(false)}
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="bg-white p-6 rounded-lg max-w-md mx-auto">
                    <h2 className="text-xl font-bold mb-4 text-red-500">Hata!</h2>
                    <p className="text-gray-600 mb-6">{errorMessage}</p>
                    <button
                        onClick={() => setShowErrorModal(false)}
                        className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600"
                    >
                        Tamam
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default PaymentForm;