// src/components/Header.tsx
import React, { useEffect, useState } from 'react';
import { useCart } from '../contexts/CartContext';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const Header: React.FC = () => {
    const { cartItems } = useCart();
    const { pathname } = useLocation();
    const [isAnimating, setIsAnimating] = useState(false);

    // Sepetteki toplam ürün sayısını hesapla
    const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

    // Animasyonu tetikleme
    useEffect(() => {
        if (totalItems > 0) {
            setIsAnimating(true);
            setTimeout(() => setIsAnimating(false), 500); // Animasyon bitişi
        }
    }, [totalItems]);

    const cartIconVariants = {
        initial: { scale: 1 },
        animate: { scale: 1.1 },
        transition: { type: 'spring', stiffness: 300, damping: 15 },
    };

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50">
            <div className="flex justify-around items-center py-2">
                <Link to="/" className={`flex flex-col items-center ${pathname === '/' ? 'text-blue-500' : 'text-gray-500'}`}>
                    <img src="/urunler.png" className="w-6 h-6 mb-1" alt="Ürünler"/>
                    <span className="text-[10px]">Ürünler</span>
                </Link>
                <Link to="/services" className={`flex flex-col items-center ${pathname === '/services' ? 'text-blue-500' : 'text-gray-500'}`}>
                    <img src="/hizmetler.png" className="w-6 h-6 mb-1" alt="Hizmetler"/>
                    <span className="text-[10px]">Hizmetler</span>
                </Link>
                <Link to="/orders" className={`flex flex-col items-center ${pathname === '/orders' ? 'text-blue-500' : 'text-gray-500'}`}>
                    <img src="/siparislerim.png" className="w-6 h-6 mb-1" alt="Siparişlerim"/>
                    <span className="text-[10px]">Siparişlerim</span>
                </Link>
                <Link to="/cart" className={`flex flex-col items-center ${pathname === '/cart' ? 'text-blue-500' : 'text-gray-500'}`}>
                    <div className="relative">
                        <img src="/sepet.png" className="w-6 h-6 mb-1" alt="Sepetim"/>
                        {totalItems > 0 && (
                            <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-3.5 h-3.5 flex items-center justify-center text-[8px]">
                                {totalItems}
                            </span>
                        )}
                    </div>
                    <span className="text-[10px]">Sepetim</span>
                </Link>
            </div>
        </div>
    );
};

export default Header;