import React from 'react';
import ServiceList from '../components/ServiceList';
import ServiceFilter from '../components/ServiceFilter';

const Services: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-100">
            <div className="sticky top-0 left-0 right-0 z-10 bg-white shadow-sm">
                <div className="container mx-auto px-4 py-2">
                    <ServiceFilter />
                </div>
            </div>
            <div className="container mx-auto px-4 py-4">
                <div className="px-0">
                    <ServiceList />
                </div>
            </div>
        </div>
    );
};

export default Services;