import React from 'react';

interface BankTransferInfoProps {
    orderNumber: string;
    totalAmount: number;
}

const BankTransferInfo: React.FC<BankTransferInfoProps> = ({
    orderNumber,
    totalAmount
}) => {
    const bankAccounts = [
        {
            bankName: "Garanti Bankası",
            accountName: "OTOKONFOR OTOMOTİV A.Ş.",
            iban: "TR12 3456 7890 1234 5678 9012 34",
            branchCode: "123",
            accountNumber: "12345678"
        },
        {
            bankName: "İş Bankası",
            accountName: "OTOKONFOR OTOMOTİV A.Ş.",
            iban: "TR98 7654 3210 9876 5432 1098 76",
            branchCode: "456",
            accountNumber: "87654321"
        }
    ];

    return (
        <div className="bg-white p-2 rounded-lg shadow-sm">
            <div className="mb-2">
                <h3 className="text-lg font-semibold mb-2">Önemli Bilgilendirme</h3>
                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-2 text-sm text-yellow-800">
                    <p>• Ödemenizi 1 iş günü içerisinde yapmanız gerekmektedir.</p>
                    <p>• Bu süre içinde ödeme yapılmaması durumunda siparişiniz otomatik olarak iptal edilecektir.</p>
                    <p>• Ödeme açıklamasına mutlaka sipariş numaranızı yazınız: <strong>{orderNumber}</strong></p>
                    <p>• Ödenecek Tutar: <strong>{totalAmount.toLocaleString('tr-TR', { minimumFractionDigits: 2 })} TL</strong></p>
                </div>
            </div>

            <h3 className="text-lg font-semibold mb-2">Banka Hesap Bilgileri</h3>
            <div className="space-y-2">
                {bankAccounts.map((account, index) => (
                    <div key={index} className="border rounded-lg p-2">
                        <div className="flex justify-between items-start mb-2">
                            <h4 className="font-medium text-lg">{account.bankName}</h4>
                        </div>
                        <div className="space-y-1 text-sm">
                            <p><span className="text-gray-600">Hesap Sahibi:</span> {account.accountName}</p>
                            <p className="flex items-center">
                                <span className="text-gray-600 mr-2">IBAN:</span>
                                <span className="font-mono bg-gray-50 p-1 rounded select-all">{account.iban}</span>
                            </p>
                            <p><span className="text-gray-600">Şube Kodu:</span> {account.branchCode}</p>
                            <p><span className="text-gray-600">Hesap No:</span> {account.accountNumber}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-2 bg-blue-50 border border-blue-200 rounded-lg p-2">
                <h4 className="font-medium text-blue-800 mb-1">Ödeme Sonrası</h4>
                <p className="text-sm text-blue-700">
                    Ödemeniz kontrol edildikten sonra siparişiniz işleme alınacaktır. 
                    Ödeme durumunuzu "Siparişlerim" sayfasından takip edebilirsiniz.
                </p>
            </div>
        </div>
    );
};

export default BankTransferInfo; 