// src/components/ProductList.tsx
import React, { useEffect, useState } from 'react';
import Product from './Product';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useProductFetcher} from '../hooks/useProductFetcher';
import {useFilter} from '../contexts/FilterContext';
import ClipLoader from 'react-spinners/ClipLoader';
import { useCity } from '../contexts/CityContext';

const ProductList: React.FC = () => {
    const { selectedCity } = useCity();
    const { filters, applyFilters } = useFilter();
    const [isFiltering, setIsFiltering] = useState(false);
    const {products, loading, error, hasMore, loadMore} = useProductFetcher(selectedCity);

    // Filtreler değiştiğinde isFiltering'i true yap
    useEffect(() => {
        setIsFiltering(true);
        // Ürünler yüklendiğinde isFiltering'i false yap
        if (!loading && isFiltering) {
            setIsFiltering(false);
        }
    }, [filters]);

    // Loading durumu değiştiğinde isFiltering'i güncelle
    useEffect(() => {
        if (!loading && isFiltering) {
            setIsFiltering(false);
        }
    }, [loading]);


    const handleClearFilters = () => {
        applyFilters({});
    };

    // Filtreleme yapılırken veya yükleme sırasında loader göster
    if ((loading && products.length === 0) || isFiltering) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50}/>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center mt-4">{error}</div>;
    }

    const CityFirmInfo = () => {
        const filteredCity = filters['filter[products.firm.city]'] || selectedCity;
        
        return (
            <div className="bg-white p-2 rounded-md shadow-sm mt-2 text-center w-100 text-sm text-gray-600" style={{ width: '100%'}}>
                <span className="font-semibold text-gray-800">{filteredCity}</span> ilindeki lastik satıcılarının ürünleri görüntülenmektedir.
                {filters['filter[products.firm.city]'] && (
                    <div className="mt-1">
                        <span className="bg-blue-100 text-blue-600 text-[10px] px-2 py-0.5 rounded-full">
                            Filtrelenmiş Şehir
                        </span>
                    </div>
                )}
            </div>
        );
    };

    if (!loading && products.length === 0) {
        return (
            <>
            <div className="flex mb-4 flex-row justify-between items-center">
                <CityFirmInfo />
            </div>
            <div className="flex flex-col items-center justify-center">
                <img
                    src="https://cdn-icons-png.flaticon.com/512/4076/4076432.png"
                    alt="No Results"
                    className="w-24 h-24 mb-4"
                />
                <p className="text-lg font-bold text-gray-700 text-center">
                    Üzgünüz, aradığınız ürün şu anda görüntülenemiyor. <br />
                    Bu durum, arama kriterleriniz veya bulunduğunuz ilde henüz anlaşmalı servis olmaması nedeniyle oluşmuş olabilir.
                </p>
                {Object.values(filters).some((value) => value) && (
                    <button
                        onClick={handleClearFilters}
                        className="px-4 py-1 bg-red-500 bg-opacity-10 text-red-500 text-[12px] rounded-md transition mt-5"
                    >
                        Filtreleri Temizle
                    </button>
                )}
            </div>
            </>
        );
    }

    return (
        <div>
            <div className="flex">
                <div className="">
                    {Object.values(filters).some((value) => value) && (
                        <button
                            onClick={handleClearFilters}
                            className="px-4 py-1 bg-red-500 bg-opacity-10 text-red-500 text-[12px] rounded-md transition mb-2"
                        >
                            Filtreleri Temizle
                        </button>
                    )}
                </div>
            </div>

            <CityFirmInfo />

            {/* Ürün Listesi */}
            <InfiniteScroll
                dataLength={products.length}
                next={loadMore}
                hasMore={hasMore}
                className='flex flex-wrap'
                loader={
                    <div className="flex justify-center mt-4">
                        <ClipLoader color="#3498db" size={30}/>
                    </div>
                }
                endMessage={
                    <div className="text-center mt-4 text-gray-500 w-100 flex-1">
                        Tüm ürünler yüklendi.
                    </div>
                }
                scrollThreshold={0.2}
            >
                {products.map((product) => (
                    <Product key={product.id} product={product}/>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default ProductList;