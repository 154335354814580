import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useServiceFetcher } from '../hooks/useServiceFetcher';
import { useFilter } from '../contexts/FilterContext';
import ClipLoader from 'react-spinners/ClipLoader';
import Filter from "./Filter";
import { ServiceType } from '../types/types';
import ServiceCard from './ServiceCard';
import { useCart } from '../contexts/CartContext';
import axiosInstance from '../axiosInstance';
import {useAuthMiddleware} from "../contexts/AuthMiddlewareContext";
import { useCity } from '../contexts/CityContext';

const ServiceList: React.FC = () => {
    const { filters, applyFilters } = useFilter();
    const { cartItems } = useCart();
    const [firmName, setFirmName] = useState<string>("");
    const [firmId, setFirmId] = useState<number>(null);
    const [showAllServices, setShowAllServices] = useState(false);
    const { userData } = useAuthMiddleware();
    const { selectedCity } = useCity();
    const [isFiltering, setIsFiltering] = useState(false);
    
    // Şehir filtresini al - servisler için firm.city kullanılmalı
    const cityFilter = filters['filter[firm.city]'] || selectedCity || userData?.il;
    
    const { services, loading, error, hasMore, loadMore, resetServices } = useServiceFetcher(
        showAllServices ? null : firmId,
        cityFilter,
        filters // Tüm filtreleri geçiyoruz, hook içinde uygun olanlar kullanılacak
    );
    
    // Filtrelerin değişimini izle
    useEffect(() => {
        console.log('Filtreler değişti:', filters);
        console.log('Kullanılan şehir filtresi:', cityFilter);
        setIsFiltering(true);
    }, [filters, cityFilter]);
    
    // Loading durumu değiştiğinde isFiltering'i güncelle
    useEffect(() => {
        if (!loading && isFiltering) {
            setIsFiltering(false);
        }
    }, [loading]);

    useEffect(() => {
        const fetchFirmName = async () => {
            if (cartItems.length > 0) {
                try {
                    const productIds = cartItems
                        .filter(item => item.product_id)
                        .map(item => item.product_id)
                        .join(',');

                    if (productIds) {
                        const response = await axiosInstance.get(`/ecommerce/basket`, {
                            params: { 'filter[id]': productIds, 'include': 'firm' },
                        });
                        if (response.data.data.length > 0) {
                            setFirmName(response.data.data[0].firm.title);
                            setFirmId(response.data.data[0].firm.id);
                            setShowAllServices(false);
                        }
                    }
                } catch (error) {
                    console.error('Firma bilgisi alınamadı:', error);
                }
            } else {
                setFirmName("");
                setFirmId(null);
                setShowAllServices(false);
            }
        };

        fetchFirmName();
    }, [cartItems]);

    const handleShowAllServices = () => {
        setShowAllServices(true);
        resetServices();
    };

    const handleClearFilters = () => {
        applyFilters({});
    };

    const CartFirmInfo = () => {
        if (!firmName) return null;

        return (
            <div className="bg-white p-2 rounded-md shadow-sm mt-5 text-center w-100" style={{ width: '100%'}}>
                {showAllServices ? (
                    "Şuan tüm hizmetleri görüntülüyorsunuz"
                ) : (
                    <>
                        Sepetinizde {firmName} firmasına ait ürünler bulunmaktadır ve bu firmaya ait hizmetleri görüntülemektesiniz.
                        <br />
                        <button
                            onClick={handleShowAllServices}
                            className="bg-green-300 text-green-800 px-2 py-1 rounded-md mt-4"
                        >
                            Tüm Hizmetleri Gör
                        </button>
                    </>
                )}
            </div>
        );
    };

    const CityFirmInfo = () => {
        return (
            <div className="bg-white p-2 rounded-md shadow-sm mt-2 text-center w-100 text-sm text-gray-600" style={{ width: '100%'}}>
                <span className="font-semibold text-gray-800">{cityFilter}</span> ilindeki lastik satıcılarının hizmetleri görüntülenmektedir.
                {filters['filter[firm.city]'] && (
                    <div className="mt-1">
                        <span className="bg-blue-100 text-blue-600 text-[10px] px-2 py-0.5 rounded-full">
                            Filtrelenmiş Şehir
                        </span>
                    </div>
                )}
            </div>
        );
    };

    if ((loading && services.length === 0) || isFiltering) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50}/>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center mt-4">{error}</div>;
    }

    if (!loading && services.length === 0) {
        return (
            <>
            <div className="flex mb-4 flex-row justify-between items-center">
                <CartFirmInfo />
            </div>
            <CityFirmInfo />
            <div className="flex flex-col items-center justify-center mt-5">
                <img
                    src="https://cdn-icons-png.flaticon.com/512/4076/4076432.png"
                    alt="No Results"
                    className="w-24 h-24 mb-4"
                />
                <p className="text-lg font-bold text-gray-700 text-center">
                    Üzgünüz, aradığınız hizmet şu anda görüntülenemiyor. <br />
                    Bu durum, arama kriterleriniz veya bulunduğunuz ilde henüz anlaşmalı servis olmaması nedeniyle oluşmuş olabilir.
                </p>
                {Object.values(filters).some((value) => value) && (
                    <button
                        onClick={handleClearFilters}
                        className="px-4 py-1 bg-red-500 bg-opacity-10 text-red-500 text-[12px] rounded-md transition mt-5"
                    >
                        Filtreleri Temizle
                    </button>
                )}
            </div>
            </>
        );
    }

    return (
        <div className="p-1">
            <div className="flex mb-4 flex-col">
                <CityFirmInfo />
                <CartFirmInfo />
            </div>

            <InfiniteScroll
                dataLength={services.length}
                next={loadMore}
                hasMore={hasMore}
                className='flex flex-wrap pb-20'
                loader={
                    <div className="flex justify-center mt-4">
                        <ClipLoader color="#3498db" size={30}/>
                    </div>
                }
                endMessage={
                    <p className="text-center mt-4 text-gray-500">
                        Tüm hizmetler yüklendi.
                    </p>
                }
            >
                {services
                .filter((service: ServiceType) => {
                    const hasProductId = cartItems.some(item => item.product_id);
                    return !(service.code === "OTK-001" && !hasProductId);
                })
                .map((service: ServiceType) => (
                    <ServiceCard key={service.id} service={service} />
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default ServiceList;