import React from 'react';
import { ServiceType } from '../types/types';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";

interface ServiceCardProps {
    service: ServiceType;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
    const navigate = useNavigate();
    const { addToCart } = useCart();
    const fallbackImage = 'https://panel.lastsis.com/media/default-images/service-default.jpeg';
    const totalPrice = parseFloat(service.price) + parseFloat(service.total_vat);

    console.log('Service Code:', service.code);

    const handleAddToCart = (e: React.MouseEvent) => {
        e.stopPropagation();
        console.log('Service ID:', service);
        if (service.firm_id) {
            addToCart(service.firm_id, {
                service_id: service.id
            });
        }
    };

    const handleServiceClick = () => {
        navigate(`/service/${service.id}`);
    };

    return (
        <div className="w-1/2 md:w-1/4">
            <div className="p-1">
                <div 
                    className="w-full bg-white rounded-[12px] shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden cursor-pointer"
                    onClick={handleServiceClick}
                >
                    <div className="relative">
                        <img
                            className="w-[175px] h-[175px] object-contain mx-auto rounded-t-[12px]"
                            alt={service.name}
                            src={`https://lastsis.s3.amazonaws.com/lastsis/uploads/icons/${service.code}.jpg`}
                            onError={(e) => {
                                (e.target as HTMLImageElement).src = fallbackImage;
                            }}
                        />
                        {service.code === "OTK-001" && (
                            <div className="absolute bottom-0 left-0 right-0 bg-red-500 bg-opacity-80 text-white py-1.5 px-2">
                                <p className="text-[11px] font-medium text-center">ÜCRETSİZ HİZMET</p>
                            </div>
                        )}
                    </div>
                    <div className="p-3">
                        <div className="flex w-100" title={service.name}>
                            <h3
                                className="font-bold text-[13px] text-[#333] h-[48px] leading-[16px] overflow-hidden text-ellipsis"
                                style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                }}
                                title={service.name}
                            >
                                {service.name}
                            </h3>
                        </div>
                        <div className="my-1 flex flex-col w-100">
                            <div className="text-[11px] text-gray-500 mb-2">
                                Kod: {service.code}
                            </div>
                            <div className="flex flex-row justify-between items-center center my-3">
                                <strong className="text-[16px] text-[#0a8c06]">
                                    {totalPrice.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} TL
                                </strong>
                                {service.sellers && service.sellers.length > 0 ?
                                <span className="text-[12px] text-[#333] font-bold">
                                    Satıcılar ({service.sellers.length ?? 0})
                                </span> : null}
                            </div>
                        </div>
                        <div className="flex justify-end items-center">
                            <button
                                onClick={handleAddToCart}
                                className="w-full px-2 py-1.5 bg-blue-500 text-white rounded-[8px] hover:bg-blue-600 transition text-[12px]"
                            >
                                <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                                Ekle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;