import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { useFilter } from "../contexts/FilterContext";
import { useCity } from "../contexts/CityContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CitySelector from './CitySelector';
import { SearchIcon, FilterIcon } from '../icons/Icons';
import Select from 'react-select';

const Filter: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [filterOptions, setFilterOptions] = useState<any>({});
    const { applyFilters } = useFilter();
    const { selectedCity, setSelectedCity } = useCity();

    const [search, setSearch] = useState('');
    const [category, setCategory] = useState('');
    const [brand, setBrand] = useState('');
    const [season, setSeason] = useState('');
    const [width, setWidth] = useState('');
    const [sidewall, setSidewall] = useState('');
    const [rim, setRim] = useState('');
    const [tempSelectedCity, setTempSelectedCity] = useState(selectedCity);

    useEffect(() => {
        setTempSelectedCity(selectedCity);
    }, [selectedCity]);

    const handleSubmit = () => {
        const newFilters: Record<string, string> = {};

        if (search) newFilters['filter[search]'] = search;
        if (category) newFilters['filter[category]'] = category;
        if (brand) newFilters['filter[brand]'] = brand;
        if (season) newFilters['filter[season]'] = season;
        if (width || sidewall || rim) {
            newFilters['filter[size]'] = `${width}/${sidewall}R${rim}`;
        }
        
        if (tempSelectedCity) {
            newFilters['filter[products.firm.city]'] = tempSelectedCity;
        }

        setSelectedCity(tempSelectedCity);
        applyFilters(newFilters);
        setIsMenuOpen(false);
    };

    useEffect(() => {
        axiosInstance.get('/ecommerce/common')
            .then((response) => setFilterOptions(response.data))
            .catch((error) => console.error('API Error:', error));
    }, []);

    const customInputStyle = `
        w-full
        px-4
        py-2.5
        rounded-lg
        bg-white
        border
        border-gray-200
        text-gray-700
        text-sm
        focus:outline-none
        focus:border-blue-500
        focus:ring-2
        focus:ring-blue-200
        transition-all
        duration-200
    `;

    const customSelectStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: '0.5rem',
            border: state.isFocused ? '2px solid #3182ce' : '1px solid #e2e8f0',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #3182ce',
            },
            minHeight: '42px',
            backgroundColor: 'white',
        }),
        option: (provided: any, state: { isSelected: boolean }) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#3182ce' : 'white',
            color: state.isSelected ? 'white' : '#1a202c',
            '&:hover': {
                backgroundColor: state.isSelected ? '#3182ce' : '#ebf8ff',
            },
            cursor: 'pointer',
            padding: '8px 12px',
            fontSize: '12px',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#1a202c',
            fontSize: '12px',
        }),
        menu: (provided: any) => ({
            ...provided,
            borderRadius: '0.5rem',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            zIndex: 50,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#718096',
            fontSize: '12px',
        }),
    };

    return (
        <div className="flex flex-row w-100 justify-between py-1 space-x-2" style={{ width: '100%'}}>
            {/* Filtrele Butonu */}
            <button
                onClick={() => setIsMenuOpen(true)}
                className="px-4 py-1.5 rounded-[6px] bg-gray-200 text-black border border-gray-300 flex flex-row items-center w-[120px] justify-center"
            >
                <img src="/filtrele.png" className={`w-[18px] mr-2`} alt=""/>
                <span className="text-[13px]">Filtrele</span>
            </button>
            <div className={`flex flex-row flex-1`}>
                <div className="flex flex-1">
                    <input
                        type="text"
                        placeholder="Arama kelimesini giriniz..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className={`flex-1 border border-gray-300 ${customInputStyle}`} 
                        style={{
                            padding: '6px 10px',
                            lineHeight: '24px',
                            border: '1px solid rgb(209, 213, 219)',
                            backgroundColor: 'rgb(229, 231, 235)',
                            borderTopRightRadius:0,
                            borderBottomRightRadius:0,
                            fontSize: '12px'
                        }}
                    />
                    <button
                        onClick={handleSubmit}
                        className="w-[60px] bg-gray-700 text-white p-1 text-[14px] hover:bg-blue-600 transition"
                        style={{
                            borderTopLeftRadius:0,
                            borderBottomLeftRadius:0,
                            borderTopRightRadius: '6px',
                            borderBottomRightRadius: '6px',
                        }}
                    >
                        Ara
                    </button>
                </div>
            </div>

            {isMenuOpen && (
                <>
                    {/* Sağdaki opak alan, menüyü kapatmak için */}
                    <div
                        className="fixed inset-0 bg-black opacity-70 z-40"
                        onClick={() => setIsMenuOpen(false)}
                    ></div>

                    {/* Filtre Menüsü (Sola Yaslanmış) */}
                    <div className="fixed inset-y-0 left-0 w-4/5 bg-white p-6 z-50 overflow-auto shadow-2xl">
                        <button
                            className="absolute top-4 right-4 h-8 w-8 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200 transition-all duration-200"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <FontAwesomeIcon icon={faTimes} size="sm" className="text-gray-600" />
                        </button>

                        <input
                            type="text"
                            placeholder="Arama kelimesini giriniz..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className={`${customInputStyle} mt-10 mb-6`}
                        />

                        <div className="space-y-4">
                            <div>
                                <CitySelector
                                    value={tempSelectedCity}
                                    onChange={setTempSelectedCity}
                                    showLabel={false}
                                />
                            </div>

                            <div>
                                <Select
                                    options={filterOptions.categories?.map((cat: string) => ({ value: cat, label: cat }))}
                                    value={category ? { value: category, label: category } : null}
                                    onChange={(option) => setCategory(option?.value || '')}
                                    styles={customSelectStyles}
                                    placeholder="Kategori seçiniz..."
                                    isClearable
                                    isSearchable
                                />
                            </div>

                            <div>
                                <Select
                                    options={filterOptions.brands?.map((b: string) => ({ value: b, label: b }))}
                                    value={brand ? { value: brand, label: brand } : null}
                                    onChange={(option) => setBrand(option?.value || '')}
                                    styles={customSelectStyles}
                                    placeholder="Marka seçiniz..."
                                    isClearable
                                    isSearchable
                                />
                            </div>

                            <div>
                                <Select
                                    options={filterOptions.seasons?.map((s: string) => ({ value: s, label: s }))}
                                    value={season ? { value: season, label: season } : null}
                                    onChange={(option) => setSeason(option?.value || '')}
                                    styles={customSelectStyles}
                                    placeholder="Mevsim seçiniz..."
                                    isClearable
                                    isSearchable
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <Select
                                        options={filterOptions.widths?.map((w: number) => ({ value: w.toString(), label: w.toString() }))}
                                        value={width ? { value: width, label: width } : null}
                                        onChange={(option) => setWidth(option?.value || '')}
                                        styles={customSelectStyles}
                                        placeholder="Taban"
                                        isClearable
                                    />
                                </div>
                                <div>
                                    <Select
                                        options={filterOptions.sidewalls?.map((s: number) => ({ value: s.toString(), label: s.toString() }))}
                                        value={sidewall ? { value: sidewall, label: sidewall } : null}
                                        onChange={(option) => setSidewall(option?.value || '')}
                                        styles={customSelectStyles}
                                        placeholder="Yanak"
                                        isClearable
                                    />
                                </div>
                                <div>
                                    <Select
                                        options={filterOptions.rims?.map((r: number) => ({ value: r.toString(), label: r.toString() }))}
                                        value={rim ? { value: rim, label: rim } : null}
                                        onChange={(option) => setRim(option?.value || '')}
                                        styles={customSelectStyles}
                                        placeholder="Jant"
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="w-full bg-gray-700 text-white py-3 rounded-lg hover:bg-blue-600 transition-all duration-200 mt-8 font-medium"
                        >
                            Arama Yap
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Filter;