import React from 'react';
import ProductList from '../components/ProductList';
import { useCity } from '../contexts/CityContext';
import Filter from '../components/Filter';

const Home: React.FC = () => {
    const { selectedCity } = useCity();

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="sticky top-0 left-0 right-0 z-10 bg-white shadow-sm">
                <div className="container mx-auto px-4 py-2">
                    <Filter />
                </div>
            </div>
            <div className="container mx-auto px-4 py-4">
                <div className="px-0">
                    <ProductList />
                </div>
            </div>
        </div>
    );
};

export default Home;