import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';

export type PaymentMethod = 'credit_card' | 'bank_transfer';

interface PaymentMethodSelectorProps {
    selectedMethod: PaymentMethod;
    onMethodChange: (method: PaymentMethod) => void;
}

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({
    selectedMethod,
    onMethodChange
}) => {
    const methods = [
        /*{
            id: 'bank_transfer' as PaymentMethod,
            title: 'Havale/EFT ile Ödeme',
            description: '1 iş günü içinde ödeme yapmanız gerekmektedir',
            icon: faMoneyBillTransfer
        },*/
        {
            id: 'credit_card' as PaymentMethod,
            title: 'Kredi Kartı ile Ödeme',
            description: 'Güvenli ödeme altyapısı ile anında ödeme yapın',
            icon: faCreditCard
        }
    ];

    return (
        <div className="space-y-4">
            <h3 className="text-lg font-semibold mb-4">Ödeme Yöntemi Seçin</h3>
            <div className="grid gap-4">
                {methods.map((method) => (
                    <div
                        key={method.id}
                        className={`border rounded-lg p-4 cursor-pointer transition-all ${
                            selectedMethod === method.id
                                ? 'border-blue-500 bg-blue-50'
                                : 'border-gray-200 hover:border-blue-300'
                        }`}
                        onClick={() => onMethodChange(method.id)}
                    >
                        <div className="flex items-start space-x-4">
                            <div className={`mt-1 text-xl ${
                                selectedMethod === method.id ? 'text-blue-500' : 'text-gray-400'
                            }`}>
                                <FontAwesomeIcon icon={method.icon} />
                            </div>
                            <div>
                                <h4 className="font-medium">{method.title}</h4>
                                <p className="text-sm text-gray-600 mt-1">{method.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PaymentMethodSelector; 