import React from 'react';
import Select from 'react-select';
import { useCity } from '../contexts/CityContext';

const cities = [
    { value: 'İSTANBUL', label: 'İstanbul' },
    { value: 'ANKARA', label: 'Ankara' },
    { value: 'İZMİR', label: 'İzmir' },
    { value: 'ADANA', label: 'Adana' },
    { value: 'ADIYAMAN', label: 'Adıyaman' },
    { value: 'AFYONKARAHİSAR', label: 'Afyonkarahisar' },
    { value: 'AĞRI', label: 'Ağrı' },
    { value: 'AKSARAY', label: 'Aksaray' },
    { value: 'AMASYA', label: 'Amasya' },
    { value: 'ANTALYA', label: 'Antalya' },
    { value: 'ARDAHAN', label: 'Ardahan' },
    { value: 'ARTVİN', label: 'Artvin' },
    { value: 'AYDIN', label: 'Aydın' },
    { value: 'BALIKESİR', label: 'Balıkesir' },
    { value: 'BARTIN', label: 'Bartın' },
    { value: 'BATMAN', label: 'Batman' },
    { value: 'BAYBURT', label: 'Bayburt' },
    { value: 'BİLECİK', label: 'Bilecik' },
    { value: 'BİNGÖL', label: 'Bingöl' },
    { value: 'BİTLİS', label: 'Bitlis' },
    { value: 'BOLU', label: 'Bolu' },
    { value: 'BURDUR', label: 'Burdur' },
    { value: 'BURSA', label: 'Bursa' },
    { value: 'ÇANAKKALE', label: 'Çanakkale' },
    { value: 'ÇANKIRI', label: 'Çankırı' },
    { value: 'ÇORUM', label: 'Çorum' },
    { value: 'DENİZLİ', label: 'Denizli' },
    { value: 'DİYARBAKIR', label: 'Diyarbakır' },
    { value: 'DÜZCE', label: 'Düzce' },
    { value: 'EDİRNE', label: 'Edirne' },
    { value: 'ELAZIĞ', label: 'Elazığ' },
    { value: 'ERZİNCAN', label: 'Erzincan' },
    { value: 'ERZURUM', label: 'Erzurum' },
    { value: 'ESKİŞEHİR', label: 'Eskişehir' },
    { value: 'GAZİANTEP', label: 'Gaziantep' },
    { value: 'GİRESUN', label: 'Giresun' },
    { value: 'GÜMÜŞHANE', label: 'Gümüşhane' },
    { value: 'HAKKARİ', label: 'Hakkari' },
    { value: 'HATAY', label: 'Hatay' },
    { value: 'IĞDIR', label: 'Iğdır' },
    { value: 'ISPARTA', label: 'Isparta' },
    { value: 'KAHRAMANMARAŞ', label: 'Kahramanmaraş' },
    { value: 'KARABÜK', label: 'Karabük' },
    { value: 'KARAMAN', label: 'Karaman' },
    { value: 'KARS', label: 'Kars' },
    { value: 'KASTAMONU', label: 'Kastamonu' },
    { value: 'KAYSERİ', label: 'Kayseri' },
    { value: 'KİLİS', label: 'Kilis' },
    { value: 'KIRIKKALE', label: 'Kırıkkale' },
    { value: 'KIRKLARELİ', label: 'Kırklareli' },
    { value: 'KIRŞEHİR', label: 'Kırşehir' },
    { value: 'KOCAELİ', label: 'Kocaeli' },
    { value: 'KONYA', label: 'Konya' },
    { value: 'KÜTAHYA', label: 'Kütahya' },
    { value: 'MALATYA', label: 'Malatya' },
    { value: 'MANİSA', label: 'Manisa' },
    { value: 'MARDİN', label: 'Mardin' },
    { value: 'MERSİN', label: 'Mersin' },
    { value: 'MUĞLA', label: 'Muğla' },
    { value: 'MUŞ', label: 'Muş' },
    { value: 'NEVŞEHİR', label: 'Nevşehir' },
    { value: 'NİĞDE', label: 'Niğde' },
    { value: 'ORDU', label: 'Ordu' },
    { value: 'OSMANİYE', label: 'Osmaniye' },
    { value: 'RİZE', label: 'Rize' },
    { value: 'SAKARYA', label: 'Sakarya' },
    { value: 'SAMSUN', label: 'Samsun' },
    { value: 'SİİRT', label: 'Siirt' },
    { value: 'SİNOP', label: 'Sinop' },
    { value: 'SİVAS', label: 'Sivas' },
    { value: 'ŞANLIURFA', label: 'Şanlıurfa' },
    { value: 'ŞIRNAK', label: 'Şırnak' },
    { value: 'TEKİRDAĞ', label: 'Tekirdağ' },
    { value: 'TOKAT', label: 'Tokat' },
    { value: 'TRABZON', label: 'Trabzon' },
    { value: 'TUNCELİ', label: 'Tunceli' },
    { value: 'UŞAK', label: 'Uşak' },
    { value: 'VAN', label: 'Van' },
    { value: 'YALOVA', label: 'Yalova' },
    { value: 'YOZGAT', label: 'Yozgat' },
    { value: 'ZONGULDAK', label: 'Zonguldak' }
];

interface CitySelectorProps {
    value?: string;
    onChange?: (city: string) => void;
    showLabel?: boolean;
}

const CitySelector: React.FC<CitySelectorProps> = ({ 
    value: propValue, 
    onChange: propOnChange,
    showLabel = true 
}) => {
    const { selectedCity, setSelectedCity } = useCity();
    
    const value = propValue !== undefined ? propValue : selectedCity;
    const onChange = propOnChange || setSelectedCity;

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: '0.5rem',
            border: state.isFocused ? '2px solid #3182ce' : '1px solid #e2e8f0',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #3182ce',
            },
            minHeight: '48px',
            width: '100%',
            backgroundColor: 'white',
        }),
        option: (provided: any, state: { isSelected: boolean }) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#3182ce' : 'white',
            color: state.isSelected ? 'white' : '#1a202c',
            '&:hover': {
                backgroundColor: state.isSelected ? '#3182ce' : '#ebf8ff',
            },
            cursor: 'pointer',
            padding: '12px 16px',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#1a202c',
            fontWeight: 500,
            fontSize: '1rem',
        }),
        menu: (provided: any) => ({
            ...provided,
            borderRadius: '0.5rem',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            zIndex: 50,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#718096',
            fontSize: '1rem',
        }),
        input: (provided: any) => ({
            ...provided,
            fontSize: '1rem',
        }),
    };

    return (
        <div className="w-full">
            <Select
                options={cities}
                value={cities.find(city => city.value === value)}
                onChange={(option) => option && onChange(option.value)}
                styles={customStyles}
                isSearchable={true}
                placeholder="İl seçiniz..."
                noOptionsMessage={() => "İl bulunamadı"}
                className="shadow-sm"
            />
        </div>
    );
};

export default CitySelector; 